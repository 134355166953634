// _scrollbar.scss
@mixin custom-scrollbar {
    // WebKit Browsers (Chrome, Safari)
    &::-webkit-scrollbar {
      width: 2px; // ancho del scrollbar
      height: 5px; // altura del scrollbar en caso de overflow-x
    }
  
    &::-webkit-scrollbar-track {
      background: transparent; // color de fondo del track
    }
  
    &::-webkit-scrollbar-thumb {
      background: transparent; // color del thumb
      border-radius: 8px; // radio de borde del thumb
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: transparent; // color del thumb al pasar el mouse
    }
  
    // Firefox
    scrollbar-width: thin; // tamaño del scrollbar
    scrollbar-color: transparent transparent; // color del thumb y del track
  }
  
  // Edge and IE (Applying custom styles for Edge and IE might be limited as they don't support full customization like WebKit and Firefox)
  @mixin edge-ie-scrollbar {
    & {
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }
  