@font-face {    
    font-family: 'Archivo'; 
    src: url('./Archivo-Regular.ttf'); 
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-Thin.ttf'); 
    font-weight: 100;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-ThinItalic.ttf'); 
    font-weight: 100;
    font-style: italic;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-ExtraLight.ttf'); 
    font-weight: 200;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-ExtraLightItalic.ttf'); 
    font-weight: 200;
    font-style: italic;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-LightItalic.ttf'); 
    font-weight: 300;
    font-style: italic;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-Light.ttf'); 
    font-weight: 300;
}

@font-face {    
    font-family: 'Archivo'; 
    src: url('./Archivo-Regular.ttf'); 
    font-weight: 400;
}

@font-face {    
    font-family: 'Archivo'; 
    src: url('./Archivo-Medium.ttf'); 
    font-weight: 500;
}

@font-face {    
    font-family: 'Archivo'; 
    src: url('./Archivo-MediumItalic.ttf'); 
    font-weight: 500;
    font-style: italic;
}

@font-face {    
    font-family: 'Archivo'; 
    src: url('./Archivo-SemiBold.ttf'); 
    font-weight: 600;
}

@font-face {    
    font-family: 'Archivo'; 
    src: url('./Archivo-SemiBoldItalic.ttf'); 
    font-weight: 600;
    font-style: italic;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-Bold.ttf'); 
    font-weight: 700;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-BoldItalic.ttf'); 
    font-weight: 700;
    font-style: italic;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-Bold.ttf'); 
    font-weight: bold;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-BoldItalic.ttf'); 
    font-weight: bold;
    font-style: italic;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-ExtraBoldItalic.ttf'); 
    font-weight: 800;
    font-style: italic;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-ExtraBold.ttf'); 
    font-weight: 800;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-Black.ttf'); 
    font-weight: 900;
}

@font-face {    
    font-family: 'Archivo';
    src: url('./Archivo-BlackItalic.ttf'); 
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: Inter;
    src: url("./Inter_VariableFont.ttf") format("truetype-variations");
    font-weight: 100 900; // Thin Black
}

@font-face {
    font-family: "Archivo Black";
    src: url("./Archivo-Black.ttf") format("truetype");
}

////////////////////// Roboto ////////////////////////////

@font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-Thin.ttf");
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-ThinItalic.ttf");
    font-weight: 100;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-Light.ttf");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-LightItalic.ttf");
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-Regular.ttf");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-Italic.ttf");
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-Medium.ttf");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-MediumItalic.ttf");
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-Bold.ttf");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-Black.ttf");
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("./roboto/Roboto-BlackItalic.ttf");
    font-weight: 900;
    font-style: italic;
  }