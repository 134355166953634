// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

    /* ------------------------- [ Start ]  color constants SAE App ------------------------- */
    --ion-background-color: var(--ion-color-Ghost-White);
    --ion-background-color-rgb: var(--ion-color-Ghost-White-rgb);
    --ion-font-family: 'Roboto';

    /* ------------------------- [ End ] color constants SAE App ------------------------- */


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////  [ START ]               ///////////////////////////////////
  ////////////////////////////           COLORS         ///////////////////////////////////
  ////////////////////////////                          ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////// 
  /////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////// [ START ] - COLOR - BRAND /////////////////////////////////////
  
   /* Blue-Cola */
  --ion-color-Blue-Cola: #008CDB;
  --ion-color-Blue-Cola-rgb: 0,140,219;
  --ion-color-Blue-Cola-contrast: #ffffff;
  --ion-color-Blue-Cola-contrast-rgb: 0,0,0;
  --ion-color-Blue-Cola-shade: #007bc1;
  --ion-color-Blue-Cola-tint: #1a98df;

  /* Medium-Persian-Blue */
  --ion-color-Medium-Persian-Blue: #006CA8;
	--ion-color-Medium-Persian-Blue-rgb: 0,108,168;
	--ion-color-Medium-Persian-Blue-contrast: #ffffff;
	--ion-color-Medium-Persian-Blue-contrast-rgb: 255,255,255;
	--ion-color-Medium-Persian-Blue-shade: #005f94;
	--ion-color-Medium-Persian-Blue-tint: #1a7bb1;

  /* Dark-Cerulean */
  --ion-color-Dark-Cerulean: #004C77;
	--ion-color-Dark-Cerulean-rgb: 0,76,119;
	--ion-color-Dark-Cerulean-contrast: #ffffff;
	--ion-color-Dark-Cerulean-contrast-rgb: 255,255,255;
	--ion-color-Dark-Cerulean-shade: #004369;
	--ion-color-Dark-Cerulean-tint: #1a5e85;

  /* Oxford-Blue */
  --ion-color-Oxford-Blue: #002A42;
	--ion-color-Oxford-Blue-rgb: 0,42,66;
	--ion-color-Oxford-Blue-contrast: #ffffff;
	--ion-color-Oxford-Blue-contrast-rgb: 255,255,255;
	--ion-color-Oxford-Blue-shade: #00253a;
	--ion-color-Oxford-Blue-tint: #1a3f55;

  /////////////////////////////// [ END - COLOR - BRAND /////////////////////



  /////////////////////////////// [ START - COLOR - NEUTRAL /////////////////////

  /* Ghost-White */
  --ion-color-Ghost-White: #FAFCFF;
	--ion-color-Ghost-White-rgb: 250,252,255;
	--ion-color-Ghost-White-contrast: #000000;
	--ion-color-Ghost-White-contrast-rgb: 0,0,0;
	--ion-color-Ghost-White-shade: #dcdee0;
	--ion-color-Ghost-White-tint: #fbfcff;

  /* AntiFlash-White */
	--ion-color-AntiFlash-White: #F0F5F8;
	--ion-color-AntiFlash-White-rgb: 240,245,248;
	--ion-color-AntiFlash-White-contrast: #000000;
	--ion-color-AntiFlash-White-contrast-rgb: 0,0,0;
	--ion-color-AntiFlash-White-shade: #d3d8da;
	--ion-color-AntiFlash-White-tint: #f2f6f9;
 
  /* Water */
	--ion-color-Water: #DAEFFA;
	--ion-color-Water-rgb: 218,239,250;
	--ion-color-Water-contrast: #000000;
	--ion-color-Water-contrast-rgb: 0,0,0;
	--ion-color-Water-shade: #c0d2dc;
	--ion-color-Water-tint: #def1fb;

  /* Disable */
	--ion-color-Disable: #ACBEC8;
	--ion-color-Disable-rgb: 172,190,200;
	--ion-color-Disable-contrast: #000000;
	--ion-color-Disable-contrast-rgb: 0,0,0;
	--ion-color-Disable-shade: #97a7b0;
	--ion-color-Disable-tint: #b4c5ce;

  /* Manatee */
	--ion-color-Manatee: #8D9DA5;
	--ion-color-Manatee-rgb: 141,157,165;
	--ion-color-Manatee-contrast: #000000;
	--ion-color-Manatee-contrast-rgb: 0,0,0;
	--ion-color-Manatee-shade: #7c8a91;
	--ion-color-Manatee-tint: #98a7ae;

  /* Slate-Grey */
	--ion-color-Slate-Grey: #70848F;
	--ion-color-Slate-Grey-rgb: 112,132,143;
	--ion-color-Slate-Grey-contrast: #000000;
	--ion-color-Slate-Grey-contrast-rgb: 0,0,0;
	--ion-color-Slate-Grey-shade: #63747e;
	--ion-color-Slate-Grey-tint: #7e909a;

  /* Japanese-Indigo */
	--ion-color-Japanese-Indigo: #243E4C;
	--ion-color-Japanese-Indigo-rgb: 36,62,76;
	--ion-color-Japanese-Indigo-contrast: #ffffff;
	--ion-color-Japanese-Indigo-contrast-rgb: 255,255,255;
	--ion-color-Japanese-Indigo-shade: #203743;
	--ion-color-Japanese-Indigo-tint: #3a515e;

  /* Dark-Gunmetal */
	--ion-color-Dark-Gunmetal: #1d2930;
	--ion-color-Dark-Gunmetal-rgb: 29,41,48;
	--ion-color-Dark-Gunmetal-contrast: #ffffff;
	--ion-color-Dark-Gunmetal-contrast-rgb: 255,255,255;
	--ion-color-Dark-Gunmetal-shade: #1a242a;
	--ion-color-Dark-Gunmetal-tint: #343e45;

  /* Chinese-Black */
	--ion-color-Chinese-Black: #0F1619;
	--ion-color-Chinese-Black-rgb: 15,22,25;
	--ion-color-Chinese-Black-contrast: #ffffff;
	--ion-color-Chinese-Black-contrast-rgb: 255,255,255;
	--ion-color-Chinese-Black-shade: #0d1316;
	--ion-color-Chinese-Black-tint: #272d30;

  /////////////////////////////// [ END - COLOR - NEUTRAL /////////////////////



  /////////////////////////////// [ START - COLOR - ACTION /////////////////////
  
  --ion-color-Error: #EE4444;
	--ion-color-Error-rgb: 238,68,68;
	--ion-color-Error-contrast: #000000;
	--ion-color-Error-contrast-rgb: 0,0,0;
	--ion-color-Error-shade: #d13c3c;
	--ion-color-Error-tint: #f05757;

	--ion-color-Error-2: #A62626;
	--ion-color-Error-2-rgb: 166,38,38;
	--ion-color-Error-2-contrast: #ffffff;
	--ion-color-Error-2-contrast-rgb: 255,255,255;
	--ion-color-Error-2-shade: #922121;
	--ion-color-Error-2-tint: #af3c3c;

	--ion-color-Warning: #FFCA0D;
	--ion-color-Warning-rgb: 255,202,13;
	--ion-color-Warning-contrast: #000000;
	--ion-color-Warning-contrast-rgb: 0,0,0;
	--ion-color-Warning-shade: #e0b20b;
	--ion-color-Warning-tint: #ffcf25;

	--ion-color-Warning-2: #D49902;
	--ion-color-Warning-2-rgb: 212,153,2;
	--ion-color-Warning-2-contrast: #000000;
	--ion-color-Warning-2-contrast-rgb: 0,0,0;
	--ion-color-Warning-2-shade: #bb8702;
	--ion-color-Warning-2-tint: #d8a31b;

	--ion-color-Info: #45B1E8;
	--ion-color-Info-rgb: 69,177,232;
	--ion-color-Info-contrast: #000000;
	--ion-color-Info-contrast-rgb: 0,0,0;
	--ion-color-Info-shade: #3d9ccc;
	--ion-color-Info-tint: #58b9ea;

	--ion-color-Info-2: #0C7CBB;
	--ion-color-Info-2-rgb: 12,124,187;
	--ion-color-Info-2-contrast: #000000;
	--ion-color-Info-2-contrast-rgb: 0,0,0;
	--ion-color-Info-2-shade: #0b6da5;
	--ion-color-Info-2-tint: #2489c2;

	--ion-color-Success: #0EAA56;
	--ion-color-Success-rgb: 14,170,86;
	--ion-color-Success-contrast: #000000;
	--ion-color-Success-contrast-rgb: 0,0,0;
	--ion-color-Success-shade: #0c964c;
	--ion-color-Success-tint: #26b367;

	--ion-color-Success-2: #11743E;
	--ion-color-Success-2-rgb: 17,116,62;
	--ion-color-Success-2-contrast: #ffffff;
	--ion-color-Success-2-contrast-rgb: 255,255,255;
	--ion-color-Success-2-shade: #0f6637;
	--ion-color-Success-2-tint: #298251;

  /////////////////////////////// [ END - COLOR - ACTION /////////////////////



  /////////////////////////////// [ START - COLOR - BUTTON /////////////////////
  
  --ion-color-Button-1: #0081DE;
	--ion-color-Button-1-rgb: 0,129,222;
	--ion-color-Button-1-contrast: #000000;
	--ion-color-Button-1-contrast-rgb: 0,0,0;
	--ion-color-Button-1-shade: #0072c3;
	--ion-color-Button-1-tint: #1a8ee1;

	--ion-color-Button-2: #0049B8;
	--ion-color-Button-2-rgb: 0,73,184;
	--ion-color-Button-2-contrast: #ffffff;
	--ion-color-Button-2-contrast-rgb: 255,255,255;
	--ion-color-Button-2-shade: #0040a2;
	--ion-color-Button-2-tint: #1a5bbf;

	--ion-color-Button-3: #0E232E;
	--ion-color-Button-3-rgb: 14,35,46;
	--ion-color-Button-3-contrast: #ffffff;
	--ion-color-Button-3-contrast-rgb: 255,255,255;
	--ion-color-Button-3-shade: #0c1f28;
	--ion-color-Button-3-tint: #263943;

	--ion-color-Button-Text: #FAFCFF;
	--ion-color-Button-Text-rgb: 250,252,255;
	--ion-color-Button-Text-contrast: #000000;
	--ion-color-Button-Text-contrast-rgb: 0,0,0;
	--ion-color-Button-Text-shade: #dcdee0;
	--ion-color-Button-Text-tint: #fbfcff;

  /////////////////////////////// [ END - COLOR - BUTTON /////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////  [ END ]                 ///////////////////////////////////
  ////////////////////////////           COLORS         ///////////////////////////////////
  ////////////////////////////                          ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////// 
  /////////////////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////  [ START ]               ///////////////////////////////////
  ////////////////////////////           TEXTS          ///////////////////////////////////
  ////////////////////////////                          ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////// 
  /////////////////////////////////////////////////////////////////////////////////////////
  
  /* Text */
	--ion-color-medium: #5B5C5B;
	--ion-color-medium-rgb: 91,92,91;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #505150;
	--ion-color-medium-tint: #6b6c6b;

  /* Text Light */
	--ion-color-light: #E3E4E4;
	--ion-color-light-rgb: 227,228,228;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #c8c9c9;
	--ion-color-light-tint: #e6e7e7;

  /* Text White */
  --ion-color-white: #ffff;
	--ion-color-white-rgb: 15,255,255;
	--ion-color-white-contrast: #000000;
	--ion-color-white-contrast-rgb: 0,0,0;
	--ion-color-white-shade: #0de0e0;
	--ion-color-white-tint: #27ffff;

  /* BLUE */
  --ion-color-blue: #097CFB;
	--ion-color-blue-rgb: 9,124,251;
	--ion-color-blue-contrast: #ffffff;
	--ion-color-blue-contrast-rgb: 255,255,255;
	--ion-color-blue-shade: #086ddd;
	--ion-color-blue-tint: #2289fb;

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////  [ END ]                 ///////////////////////////////////
  ////////////////////////////           TEXTS          ///////////////////////////////////
  ////////////////////////////                          ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////// 
  /////////////////////////////////////////////////////////////////////////////////////////


}
  /*
   * Dark Colors
   * -------------------------------------------
   */

   body.dark {

    /* Lime Green_White */
    --ion-color-primary: #E8EEE9;
    --ion-color-primary-rgb: 232,238,233;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #ccd1cd;
    --ion-color-primary-tint: #eaf0eb;

    /* Other3 */
    --ion-color-primary-two: #263F3F;
    --ion-color-primary-two-rgb: 38,63,63;
    --ion-color-primary-two-contrast: #ffffff;
    --ion-color-primary-two-contrast-rgb: 255,255,255;
    --ion-color-primary-two-shade: #213737;
    --ion-color-primary-two-tint: #3c5252;

    /* Text */
    --ion-color-secondary: #5B5C5B;
    --ion-color-secondary-rgb: 91,92,91;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #505150;
    --ion-color-secondary-tint: #6b6c6b;

    /* Cyan_VeryDark */
    --ion-color-secondary-two: #206A66;
    --ion-color-secondary-two-rgb: 32,106,102;
    --ion-color-secondary-two-contrast: #ffffff;
    --ion-color-secondary-two-contrast-rgb: 255,255,255;
    --ion-color-secondary-two-shade: #1c5d5a;
    --ion-color-secondary-two-tint: #367975;

    /* Text Light */
    --ion-color-secondary-three: #E3E4E4;
    --ion-color-secondary-three-rgb: 227,228,228;
    --ion-color-secondary-three-contrast: #000000;
    --ion-color-secondary-three-contrast-rgb: 0,0,0;
    --ion-color-secondary-three-shade: #c8c9c9;
    --ion-color-secondary-three-tint: #e6e7e7;

    /* Other4 */
    --ion-color-tertiary: #556A68;
    --ion-color-tertiary-rgb: 85,106,104;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #4b5d5c;
    --ion-color-tertiary-tint: #667977;

    /* Other2 */
    --ion-color-tertiary-two: #8FA2A0;
    --ion-color-tertiary-two-rgb: 143,162,160;
    --ion-color-tertiary-two-contrast: #000000;
    --ion-color-tertiary-two-contrast-rgb: 0,0,0;
    --ion-color-tertiary-two-shade: #7e8f8d;
    --ion-color-tertiary-two-tint: #9aabaa;

    /* Text Light */
    --ion-color-tertiary-three: #E3E4E4;
    --ion-color-tertiary-three-rgb: 227,228,228;
    --ion-color-tertiary-three-contrast: #000000;
    --ion-color-tertiary-three-contrast-rgb: 0,0,0;
    --ion-color-tertiary-three-shade: #c8c9c9;
    --ion-color-tertiary-three-tint: #e6e7e7;

    /* Other4 */
    --ion-color-tertiary_four: #556A68;
    --ion-color-tertiary_four-rgb: 85,106,104;
    --ion-color-tertiary_four-contrast: #ffffff;
    --ion-color-tertiary_four-contrast-rgb: 255,255,255;
    --ion-color-tertiary_four-shade: #4b5d5c;
    --ion-color-tertiary_four-tint: #667977;

    /* Success */
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45,211,111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /* Warning */
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,196,9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /* Soft Red */
    --ion-color-danger: #FA7373;
    --ion-color-danger-rgb: 250,115,115;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0,0,0;
    --ion-color-danger-shade: #dc6565;
    --ion-color-danger-tint: #fb8181;

    /* Soft Red_Light */
    --ion-color-danger-light: #FDCDBD;
    --ion-color-danger-light-rgb: 253,205,189;
    --ion-color-danger-light-contrast: #000000;
    --ion-color-danger-light-contrast-rgb: 0,0,0;
    --ion-color-danger-light-shade: #dfb4a6;
    --ion-color-danger-light-tint: #fdd2c4;

    /* Disabled */
    --ion-color-medium: #B5C7C5;
    --ion-color-medium-rgb: 181,199,197;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #9fafad;
    --ion-color-medium-tint: #bccdcb;

    /* Text Light */
    --ion-color-light: #E3E4E4;
    --ion-color-light-rgb: 227,228,228;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #c8c9c9;
    --ion-color-light-tint: #e6e7e7;

    /* Text white */
    --ion-color-white: #fffff;
    --ion-color-white-rgb: 15,255,255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0,0,0;
    --ion-color-white-shade: #0de0e0;
    --ion-color-white-tint: #27ffff;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

   .ios body.dark, .md body.dark {
    --ion-background-color: var(--ion-color-primary-two);
    --ion-background-color-rgb: var(--ion-color-primary-two-rgb);
    --ion-font-family: 'Archivo';

    --ion-text-color: var(--ion-color-light);
    --ion-text-color-rgb: var(--ion-color-light-rgb);

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: transparent;

    --ion-card-background: #1c1c1d;
  }

  .ios body.dark ion-modal, .md body.dark ion-modal  {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

/* DECLARE CLASS COLOR */
  .ion-color-primary-two{
    --ion-color-base: var(--ion-color-primary-two);
    --ion-color-base-rgb: var(--ion-color-primary-two-rgb);
    --ion-color-contrast: var(--ion-color-primary-two-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-two-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-two-shade);
    --ion-color-tint: var(--ion-color-primary-two-tint);
  }

  .ion-color-secondary-two{
    --ion-color-base: var(--ion-color-secondary-two);
    --ion-color-base-rgb: var(--ion-color-secondary-two-rgb);
    --ion-color-contrast: var(--ion-color-secondary-two-contrast);
    --ion-color-contrast-rgb: var(--ion-color-secondary-two-contrast-rgb);
    --ion-color-shade: var(--ion-color-secondary-two-shade);
    --ion-color-tint: var(--ion-color-secondary-two-tint);
  }

  .ion-color-secondary-three{
    --ion-color-base: var(--ion-color-secondary-three);
    --ion-color-base-rgb: var(--ion-color-secondary-three-rgb);
    --ion-color-contrast: var(--ion-color-secondary-three-contrast);
    --ion-color-contrast-rgb: var(--ion-color-secondary-three-contrast-rgb);
    --ion-color-shade: var(--ion-color-secondary-three-shade);
    --ion-color-tint: var(--ion-color-secondary-three-tint);
  }

  .ion-color-secondary-four{
    --ion-color-base: var(--ion-color-secondary-four);
    --ion-color-base-rgb: var(--ion-color-secondary-four-rgb);
    --ion-color-contrast: var(--ion-color-secondary-four-contrast);
    --ion-color-contrast-rgb: var(--ion-color-secondary-four-contrast-rgb);
    --ion-color-shade: var(--ion-color-secondary-four-shade);
    --ion-color-tint: var(--ion-color-secondary-four-tint);
  }

  .ion-color-secondary-five{
    --ion-color-base: var(--ion-color-secondary-five);
    --ion-color-base-rgb: var(--ion-color-secondary-five-rgb);
    --ion-color-contrast: var(--ion-color-secondary-five-contrast);
    --ion-color-contrast-rgb: var(--ion-color-secondary-five-contrast-rgb);
    --ion-color-shade: var(--ion-color-secondary-five-shade);
    --ion-color-tint: var(--ion-color-secondary-five-tint);
  }

  .ion-color-tertiary-two{
    --ion-color-base: var(--ion-color-tertiary-two);
    --ion-color-base-rgb: var(--ion-color-tertiary-two-rgb);
    --ion-color-contrast: var(--ion-color-tertiary-two-contrast);
    --ion-color-contrast-rgb: var(--ion-color-tertiary-two-contrast-rgb);
    --ion-color-shade: var(--ion-color-tertiary-two-shade);
    --ion-color-tint: var(--ion-color-tertiary-two-tint);
  }

  .ion-color-tertiary-three{
    --ion-color-base: var(--ion-color-tertiary-three);
    --ion-color-base-rgb: var(--ion-color-tertiary-three-rgb);
    --ion-color-contrast: var(--ion-color-tertiary-three-contrast);
    --ion-color-contrast-rgb: var(--ion-color-tertiary-three-contrast-rgb);
    --ion-color-shade: var(--ion-color-tertiary-three-shade);
    --ion-color-tint: var(--ion-color-tertiary-three-tint);
  }

  .ion-color-tertiary-four{
    --ion-color-base: var(--ion-color-tertiary-four);
    --ion-color-base-rgb: var(--ion-color-tertiary-four-rgb);
    --ion-color-contrast: var(--ion-color-tertiary-four-contrast);
    --ion-color-contrast-rgb: var(--ion-color-tertiary-four-contrast-rgb);
    --ion-color-shade: var(--ion-color-tertiary-four-shade);
    --ion-color-tint: var(--ion-color-tertiary-four-tint);
  }

  .ion-color-danger-light{
    --ion-color-base: var(--ion-color-danger-light);
    --ion-color-base-rgb: var(--ion-color-danger-light-rgb);
    --ion-color-contrast: var(--ion-color-danger-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-danger-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-danger-light-shade);
    --ion-color-tint: var(--ion-color-danger-light-tint);
  }

  .ion-color-white{
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
  }

  .ion-color-blue{
    --ion-color-base: var(--ion-color-blue);
    --ion-color-base-rgb: var(--ion-color-blue-rgb);
    --ion-color-contrast: var(--ion-color-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-shade);
    --ion-color-tint: var(--ion-color-blue-tint);
  }

.ion-color-Ghost-White {
  --ion-color-base: var(--ion-color-Ghost-White);
  --ion-color-base-rgb: var(--ion-color-Ghost-White-rgb);
  --ion-color-contrast: var(--ion-color-Ghost-White-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Ghost-White-contrast-rgb);
  --ion-color-shade: var(--ion-color-Ghost-White-shade);
  --ion-color-tint: var(--ion-color-Ghost-White-tint);
}

.ion-color-AntiFlash-White {
  --ion-color-base: var(--ion-color-AntiFlash-White);
  --ion-color-base-rgb: var(--ion-color-AntiFlash-White-rgb);
  --ion-color-contrast: var(--ion-color-AntiFlash-White-contrast);
  --ion-color-contrast-rgb: var(--ion-color-AntiFlash-White-contrast-rgb);
  --ion-color-shade: var(--ion-color-AntiFlash-White-shade);
  --ion-color-tint: var(--ion-color-AntiFlash-White-tint);
}

.ion-color-Water {
  --ion-color-base: var(--ion-color-Water);
  --ion-color-base-rgb: var(--ion-color-Water-rgb);
  --ion-color-contrast: var(--ion-color-Water-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Water-contrast-rgb);
  --ion-color-shade: var(--ion-color-Water-shade);
  --ion-color-tint: var(--ion-color-Water-tint);
}

.ion-color-Japanese-Indigo {
  --ion-color-base: var(--ion-color-Japanese-Indigo);
  --ion-color-base-rgb: var(--ion-color-Japanese-Indigo-rgb);
  --ion-color-contrast: var(--ion-color-Japanese-Indigo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Japanese-Indigo-contrast-rgb);
  --ion-color-shade: var(--ion-color-Japanese-Indigo-shade);
  --ion-color-tint: var(--ion-color-Japanese-Indigo-tint);
}

.ion-color-Dark-Gunmetal {
  --ion-color-base: var(--ion-color-Dark-Gunmetal);
  --ion-color-base-rgb: var(--ion-color-Dark-Gunmetal-rgb);
  --ion-color-contrast: var(--ion-color-Dark-Gunmetal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Dark-Gunmetal-contrast-rgb);
  --ion-color-shade: var(--ion-color-Dark-Gunmetal-shade);
  --ion-color-tint: var(--ion-color-Dark-Gunmetal-tint);
}

.ion-color-Blue-Cola {
  --ion-color-base: var(--ion-color-Blue-Cola);
  --ion-color-base-rgb: var(--ion-color-Blue-Cola-rgb);
  --ion-color-contrast: var(--ion-color-Blue-Cola-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Blue-Cola-contrast-rgb);
  --ion-color-shade: var(--ion-color-Blue-Cola-shade);
  --ion-color-tint: var(--ion-color-Blue-Cola-tint);
}

.ion-color-Medium-Persian-Blue {
  --ion-color-base: var(--ion-color-Medium-Persian-Blue);
  --ion-color-base-rgb: var(--ion-color-Medium-Persian-Blue-rgb);
  --ion-color-contrast: var(--ion-color-Medium-Persian-Blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Medium-Persian-Blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-Medium-Persian-Blue-shade);
  --ion-color-tint: var(--ion-color-Medium-Persian-Blue-tint);
}

.ion-color-Dark-Cerulean {
  --ion-color-base: var(--ion-color-Dark-Cerulean);
  --ion-color-base-rgb: var(--ion-color-Dark-Cerulean-rgb);
  --ion-color-contrast: var(--ion-color-Dark-Cerulean-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Dark-Cerulean-contrast-rgb);
  --ion-color-shade: var(--ion-color-Dark-Cerulean-shade);
  --ion-color-tint: var(--ion-color-Dark-Cerulean-tint);
}

.ion-color-Oxford-Blue {
  --ion-color-base: var(--ion-color-Oxford-Blue);
  --ion-color-base-rgb: var(--ion-color-Oxford-Blue-rgb);
  --ion-color-contrast: var(--ion-color-Oxford-Blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Oxford-Blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-Oxford-Blue-shade);
  --ion-color-tint: var(--ion-color-Oxford-Blue-tint);
}

.ion-color-Slate-Grey {
  --ion-color-base: var(--ion-color-Slate-Grey);
  --ion-color-base-rgb: var(--ion-color-Slate-Grey-rgb);
  --ion-color-contrast: var(--ion-color-Slate-Grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Slate-Grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-Slate-Grey-shade);
  --ion-color-tint: var(--ion-color-Slate-Grey-tint);
}

.ion-color-Manatee {
  --ion-color-base: var(--ion-color-Manatee);
  --ion-color-base-rgb: var(--ion-color-Manatee-rgb);
  --ion-color-contrast: var(--ion-color-Manatee-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Manatee-contrast-rgb);
  --ion-color-shade: var(--ion-color-Manatee-shade);
  --ion-color-tint: var(--ion-color-Manatee-tint);
}

.ion-color-Disable {
  --ion-color-base: var(--ion-color-Disable);
  --ion-color-base-rgb: var(--ion-color-Disable-rgb);
  --ion-color-contrast: var(--ion-color-Disable-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Disable-contrast-rgb);
  --ion-color-shade: var(--ion-color-Disable-shade);
  --ion-color-tint: var(--ion-color-Disable-tint);
}

.ion-color-Button-1 {
  --ion-color-base: var(--ion-color-Button-1);
  --ion-color-base-rgb: var(--ion-color-Button-1-rgb);
  --ion-color-contrast: var(--ion-color-Button-1-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Button-1-contrast-rgb);
  --ion-color-shade: var(--ion-color-Button-1-shade);
  --ion-color-tint: var(--ion-color-Button-1-tint);
}

.ion-color-Button-2 {
  --ion-color-base: var(--ion-color-Button-2);
  --ion-color-base-rgb: var(--ion-color-Button-2-rgb);
  --ion-color-contrast: var(--ion-color-Button-2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Button-2-contrast-rgb);
  --ion-color-shade: var(--ion-color-Button-2-shade);
  --ion-color-tint: var(--ion-color-Button-2-tint);
}

.ion-color-Button-3 {
  --ion-color-base: var(--ion-color-Button-3);
  --ion-color-base-rgb: var(--ion-color-Button-3-rgb);
  --ion-color-contrast: var(--ion-color-Button-3-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Button-3-contrast-rgb);
  --ion-color-shade: var(--ion-color-Button-3-shade);
  --ion-color-tint: var(--ion-color-Button-3-tint);
}

.ion-color-Success {
  --ion-color-base: var(--ion-color-Success);
  --ion-color-base-rgb: var(--ion-color-Success-rgb);
  --ion-color-contrast: var(--ion-color-Success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Success-contrast-rgb);
  --ion-color-shade: var(--ion-color-Success-shade);
  --ion-color-tint: var(--ion-color-Success-tint);
}

.ion-color-Error {
  --ion-color-base: var(--ion-color-Error);
  --ion-color-base-rgb: var(--ion-color-Error-rgb);
  --ion-color-contrast: var(--ion-color-Error-contrast);
  --ion-color-contrast-rgb: var(--ion-color-Error-contrast-rgb);
  --ion-color-shade: var(--ion-color-Error-shade);
  --ion-color-tint: var(--ion-color-Error-tint);
}

.ion-color-Info {
	--ion-color-base: var(--ion-color-Info);
	--ion-color-base-rgb: var(--ion-color-Info-rgb);
	--ion-color-contrast: var(--ion-color-Info-contrast);
	--ion-color-contrast-rgb: var(--ion-color-Info-contrast-rgb);
	--ion-color-shade: var(--ion-color-Info-shade);
	--ion-color-tint: var(--ion-color-Info-tint);
}