@import "/src/styles/common/scrollbar.scss";

* {
    word-wrap: normal;

    // @media (max-width: 768px) {
    //     ion-content {
    //         --padding-top: 58px;
    //     }
    // }

    --ion-mode: "md" !important;

    // ion-item{
    //     --background: transparent;
    // }

    ion-item > ion-input,
    ion-item {
        min-height: 56px !important;
    }
    ion-input,
    ion-item > ion-label {
        font-size: 14px;
        // font-family: 'Archivo' !important;
        font-weight: 700;
    }

    ion-button,
    ion-button > ion-label {
        font-size: 16px;
        // font-family: 'Archivo' !important;
        font-weight: 700;
    }

    ion-list {
        --ion-margin: 24px;
        margin-top: 8px;
        ion-item-group > ion-item-divider {
            --background: transparent;
            --padding-start: 18px;
            border: 0;
            > ion-label {
                font-size: 14px !important;
                line-height: 20px !important; /* 142.857% */
                font-weight: 600;
            }
        }
    }

    ion-button {
        white-space: nowrap;   
        text-align: center; 
    }
}

.input-pass {
    --padding-end: 50px !important;
}

.btn-show-pass {
    position: absolute;
    right: 5px;
    top: 50px;
    z-index: 10;
}

ion-input {
    &[type="number"] {
        ::ng-deep {
            input.native-input.sc-ion-input-md {
                -webkit-appearance: textfield;
                -moz-appearance: textfield;
                appearance: textfield;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
            }
        }
      }
      &.input-disabled,
      &.onlyread {
          opacity: 1;
          font-weight: normal;
          ::ng-deep .input-wrapper {
              border-width: 0;
          }
      }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Quitar los iconos en Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

// -------------------------------------- Padding -----------------------------------------

.padding-xs,
.padding-small {
    padding: 8px;
}

.padding-md,
.padding-medium {
    padding: 16px;
}

.padding-lg,
.padding-large {
    padding: 32px;
}

.padding-xl,
.padding-extralarge {
    padding: 64px;
}
// -------------------------------------- END - Padding -----------------------------------------

// -------------------------------------- Padding left -----------------------------------------
.padding-left-xs,
.padding-left-small {
    padding-left: 8px;
}

.padding-left-md,
.padding-left-medium {
    padding-left: 16px;
}

.padding-left-lg,
.padding-left-large {
    padding-left: 32px;
}

.padding-left-xl,
.padding-left-extralarge {
    padding-left: 64px;
}
// -------------------------------------- END - Padding left -----------------------------------------

// -------------------------------------- Padding right -----------------------------------------
.padding-right-xs,
.padding-right-small {
    padding-right: 8px;
}

.padding-right-md,
.padding-right-medium {
    padding-right: 16px;
}

.padding-right-lg,
.padding-right-large {
    padding-right: 32px;
}

.padding-right-xl,
.padding-right-extralarge {
    padding-right: 64px;
}
// -------------------------------------- END - Padding right -----------------------------------------

// -------------------------------------- Padding top -----------------------------------------
.padding-top-xs,
.padding-top-small {
    padding-top: 8px;
}

.padding-top-md,
.padding-top-medium {
    padding-top: 16px;
}

.padding-top-lg,
.padding-top-large {
    padding-top: 32px;
}

.padding-top-xl,
.padding-top-extralarge {
    padding-top: 64px;
}
// -------------------------------------- END - Padding top -----------------------------------------

// -------------------------------------- Padding bottom -----------------------------------------
.padding-bottom-xs,
.padding-bottom-small {
    padding-bottom: 8px;
}

.padding-bottom-md,
.padding-bottom-medium {
    padding-bottom: 16px;
}

.padding-bottom-lg,
.padding-bottom-large {
    padding-bottom: 32px;
}

.padding-bottom-xl,
.padding-bottom-extralarge {
    padding-bottom: 64px;
}
// -------------------------------------- END - Padding bottom -----------------------------------------

// -------------------------------------- padding Horizontal -----------------------------------------
.padding-horizontal-xs,
.padding-horizontal-small {
    @extend .padding-left-xs, .padding-right-xs;
}

.padding-horizontal-md,
.padding-horizontal-medium {
    @extend .padding-left-md, .padding-right-md;
}

.padding-horizontal-lg,
.padding-horizontal-large {
    @extend .padding-left-lg, .padding-right-lg;
}

.padding-horizontal-xl,
.padding-horizontal-extralarge {
    @extend .padding-left-xl, .padding-right-xl;
}
// -------------------------------------- END - padding Horizontal -----------------------------------------

// -------------------------------------- padding Vertical -----------------------------------------
.padding-vertical-xs,
.padding-vertical-small {
    @extend .padding-top-xs, .padding-bottom-xs;
}

.padding-vertical-md,
.padding-vertical-medium {
    @extend .padding-top-md, .padding-bottom-md;
}

.padding-vertical-lg,
.padding-vertical-large {
    @extend .padding-top-lg, .padding-bottom-lg;
}

.padding-vertical-xl,
.padding-vertical-extralarge {
    @extend .padding-top-xl, .padding-bottom-xl;
}
// -------------------------------------- END - margin Vertical -----------------------------------------

// -------------------------------------- margin -----------------------------------------
.margin-xs,
.margin-small {
    margin: 8px;
}

.margin-md,
.margin-medium {
    margin: 16px;
}

.margin-lg,
.margin-large {
    margin: 32px;
}

.margin-xl,
.margin-extralarge {
    margin: 64px;
}
// -------------------------------------- END - margin -----------------------------------------

// -------------------------------------- margin left -----------------------------------------
.margin-left-xs,
.margin-left-small {
    margin-left: 8px;
}

.margin-left-md,
.margin-left-medium {
    margin-left: 16px;
}

.margin-left-lg,
.margin-left-large {
    margin-left: 32px;
}

.margin-left-xl,
.margin-left-extralarge {
    margin-left: 64px;
}
// -------------------------------------- END - margin left -----------------------------------------

// -------------------------------------- margin right -----------------------------------------
.margin-right-xs,
.margin-right-small {
    margin-right: 8px;
}

.margin-right-md,
.margin-right-medium {
    margin-right: 16px;
}

.margin-right-lg,
.margin-right-large {
    margin-right: 32px;
}

.margin-right-xl,
.margin-right-extralarge {
    margin-right: 64px;
}
// -------------------------------------- END - margin right -----------------------------------------

// -------------------------------------- margin top -----------------------------------------
.margin-top-xs,
.margin-top-small {
    margin-top: 8px;
}

.margin-top-md,
.margin-top-medium {
    margin-top: 16px;
}

.margin-top-lg,
.margin-top-large {
    margin-top: 32px;
}

.margin-top-xl,
.margin-top-extralarge {
    margin-top: 64px;
}
// -------------------------------------- END - margin top -----------------------------------------

// -------------------------------------- margin bottom -----------------------------------------
.margin-bottom-xs,
.margin-bottom-small {
    margin-bottom: 8px;
}

.margin-bottom-md,
.margin-bottom-medium {
    margin-bottom: 16px;
}

.margin-bottom-lg,
.margin-bottom-large {
    margin-bottom: 32px;
}

.margin-bottom-xl,
.margin-bottom-extralarge {
    margin-right: 64px;
}
// -------------------------------------- END - margin bottom -----------------------------------------

// -------------------------------------- margin Horizontal -----------------------------------------
.margin-horizontal-xs,
.margin-horizontal-small {
    @extend .margin-left-xs, .margin-right-xs;
}

.margin-horizontal-md,
.margin-horizontal-medium {
    @extend .margin-left-md, .margin-right-md;
}

.margin-horizontal-lg,
.margin-horizontal-large {
    @extend .margin-left-lg, .margin-right-lg;
}

.margin-horizontal-xl,
.margin-horizontal-extralarge {
    @extend .margin-left-xl, .margin-right-xl;
}
// -------------------------------------- END - margin Horizontal -----------------------------------------

// -------------------------------------- margin Vertical -----------------------------------------
.margin-vertical-xs,
.margin-vertical-small {
    @extend .margin-top-xs, .margin-bottom-xs;
}

.margin-vertical-md,
.margin-vertical-medium {
    @extend .margin-top-md, .margin-bottom-md;
}

.margin-vertical-lg,
.margin-vertical-large {
    @extend .margin-top-lg, .margin-bottom-lg;
}

.margin-vertical-xl,
.margin-vertical-extralarge {
    @extend .margin-top-xl, .margin-bottom-xl;
}
// -------------------------------------- END - margin Vertical -----------------------------------------

// -------------------------------------- Font size -----------------------------------------
.font-size-small,
.font-size-xs {
    font-size: 12px;
}

.font-size-medium,
.font-size-md {
    font-size: 16px;
}

.font-size-large,
.font-size-lg {
    font-size: 20px;
}

.font-size-extralarge,
.font-size-xl {
    font-size: 24px;
}
// -------------------------------------- END - Font size -----------------------------------------

/* STYLES FOR MODULES */

.action-sheet-select {
    @extend .font-size-xs;
}

/* END - STYLES FOR MODULES */

/////////////////////////// TOAT - BATTERY ///////////////////////////////

.toastBatery {
    --height: 59px;
    text-align: center;
    font-weight: 600;
    --border-radius: 100px;
    padding: 20px;
}

/////////////////////////////////////////////////////////////////////////

.successful-fullscreen {
    backdrop-filter: blur(5px);
    filter: blur(5px);
    height: 100% !important;
}

.content-modal-notify {
    --background: auto;
    --box-shadow: 0;
    margin: auto !important;
}

.btn-action-create {
  --background: var(--ion-color-primary);
  color: var(--ion-color-light) !important;
}

.content-modal-receipt {
  --box-shadow: 1px 8px 40px #7f7b7b;
  --min-width: 90%;
  --background: #E8EEE9;
  --min-height: 90%;
  white-space: nowrap;
  --border-radius: 12px;
  --ion-backdrop-opacity: 0.7;
  --ion-backdrop-color: var(--ion-color-Dark-Cerulean);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

ion-modal.google-map-modal {
    --height: 70%;
    --width: 95%;
    --border-radius: 1rem;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    &::part(backdrop) {
        background: rgba(209, 213, 219);
        opacity: 1;
    }
    ion-content {
        --padding-top: 0;
    }
}

.chart-axies-labels {
    fill: var(--ion-color-tertiary-four);
    @extend .font-size-xs;
}

.modal-confirm-delete {
    --max-width: 75%;
    --max-height: 35%;
    --border-radius: 25px;
    --ion-backdrop-opacity: 0.7;
    --ion-backdrop-color: var(--ion-color-Dark-Cerulean);
    backdrop-filter: blur(5px);
}

.modal-confirm-delete-height-dinamic {
    @extend .modal-confirm-delete;
    --height: auto;
    --max-height: 100vh;
    height: var(--height) !important;
    max-height: var(--max-height) !important;
    overflow-y: auto;
    @include custom-scrollbar;
    @include edge-ie-scrollbar;
}

.modal-images {
    --max-width: 100%;
    --heigth: 50%;
    --max-height: 50%;
    --ion-backdrop-opacity: 0.7;
    --ion-backdrop-color: var(--ion-color-Dark-Cerulean);
    backdrop-filter: blur(5px);
    --background: transparent;
}

ion-modal.modal-date-dinamic-form {
    --width: 80%;
    --height: fit-content;
    --border-radius: 16px;
    --ion-backdrop-color: var(--ion-color-Dark-Cerulean);
    backdrop-filter: blur(5px);
}

ion-modal.modal-administrator-receipts-payment {
    @extend .modal-confirm-delete;
    --width: 60%;
    --height: auto;
    --max-height: 100vh;
    --min-height: 600px;
    height: var(--height) !important;
    max-height: var(--max-height) !important;
    overflow-y: auto;
    --border-radius: 8px;
}

.datetime-buttons {
    display: block !important;
}

.modal-notifications-push {
    --height: 90%;
    bottom: 0;
}

////////////////////////////////////////////

.btn-action-compound-save {
    box-shadow: 0 4px 10px #8fa2a066;
    color: var(--ion-color-primary-two);
    font-feature-settings:
        "clig" off,
        "liga" off;
    font-family: Archivo;
    font-size: 16px;
    min-height: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-transform: none;
    border-radius: 10px;
    --border-radius: 10px;
    background: var(--ion-color-primary);
    --border-width: 2px;
    --border-color: var(--ion-color-primary);
}

.btn-action-compound-edit {
    box-shadow: 0 4px 10px #8fa2a066;
    color: var(--ion-color-primary-two);
    font-feature-settings:
        "clig" off,
        "liga" off;
    font-family: Archivo;
    font-size: 16px;
    min-height: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-transform: none;
    border-radius: 10px;
    --border-radius: 10px;
    background: var(--ion-color-primary);
    --border-width: 2px;
    --border-color: var(--ion-color-primary);
}

.btn-action-compound-create {
    box-shadow: 0 4px 10px #8fa2a066;
    color: var(--ion-color-primary-two);
    font-feature-settings:
        "clig" off,
        "liga" off;
    font-family: Archivo;
    font-size: 16px;
    min-height: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-transform: none;
    border-radius: 10px;
    --border-radius: 10px;
    background: var(--ion-color-primary);
    --border-width: 2px;
    --border-color: var(--ion-color-primary);
}

.picker-columns {
  div {
    border: transparent !important;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
  }
}

.btn-action-picker-cancel {
  color: var(--ion-color-danger) !important;
  font-weight: bold;
}

.btn-action-compound {
    color: var(--ion-color-primary);
    font-feature-settings:
        "clig" off,
        "liga" off;
    font-family: Archivo;
    font-size: 16px;
    min-height: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-transform: none;
    border-radius: 10px;
    --border-radius: 10px;
    --background: #ffff;
    --border-width: 2px;
    --border-color: var(--ion-color-primary);
}

///////////////////////////////////////////

.asterisk-if-mandatory::after {
    content: " *";
    color: var(--ion-color-danger);
}

ion-content {
    --offset-bottom: auto !important;
    --overflow: auto;
    overflow: auto;
    @include custom-scrollbar;
    @include edge-ie-scrollbar;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: var(--ion-color-tertiary);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--ion-color-secondary);
    }

    .inner-scroll {
        scrollbar-width: thin;
    }
}

.modal-date-expense-form {
    --height: auto;
    --max-height: 100vh;
    height: var(--height) !important;
    max-height: var(--max-height) !important;
    overflow-y: auto;
    @include custom-scrollbar;
    @include edge-ie-scrollbar;
    --width: auto;
}

.modal-general-receipt-item-detail {
    --width: 1076px;
    --border-radius: 8px;
}

.action-sheet-select {
    --height: 80%;
}

.picker-opt-selected {
  max-width: 150px;
  box-shadow: 0px 0px 0px 0px rgb(68 79 79 / 56%), 0px 0px 8px 5px rgb(65 84 84 / 8%);
  border-radius: 8px;
  color: var(--ion-color-tertiary-two);
  font-weight: 900;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) !important;
  margin: auto;
  padding: 0px 10px;
  overflow: visible !important;
  @include custom-scrollbar;
  @include edge-ie-scrollbar;
  opacity: 1;
}

.modal-auxiliary-form-expense {
    @extend .modal-confirm-delete;
    --min-height: 50%;
    // --height: auto;
    // --max-height: 100vh;
    min-height: var(--min-height) !important;
    // height: var(--height) !important;
    // max-height: var(--max-height) !important;
    overflow-y: auto;
    @include custom-scrollbar;
    @include edge-ie-scrollbar;
}

.modal-auxiliary-form-lot {
    @extend .modal-confirm-delete;
    --min-height: 70%;
    --min-width: 70%;
    // --height: auto;
    // --max-height: 100vh;
    min-height: var(--min-height) !important;
    // height: var(--height) !important;
    // max-height: var(--max-height) !important;
    overflow-y: auto;
    @include custom-scrollbar;
    @include edge-ie-scrollbar;
}

.modal-select-properties-personalized-receipts{
    @extend .modal-confirm-delete-height-dinamic;
    --max-width: 80%;
    --width: 65%;
}


@media(max-width: 576px){
    section.header{
        .info {
            gap: 20px !important;
            ion-label {
                font-size: 20px!important;
            }
        }
    }
}


@media(max-width: 335px){
    section.header{
        .info {
            ion-img {
            display: none;
            }
        }
    }
}

/**
 *  Filter component - common
 */
.component-modal-filters{
 --width: auto;
 --height: auto;
 --border-radius: 8px;
 --border: 2px solid var(--ion-color-tertiary);
 --background: var(--ion-color-primary-two);
 --box-shadow: 0px 2px 10px 0px rgba(143, 162, 160, 0.40);
}

.modal-select-multiple-properties{
    --ion-backdrop-opacity: 1;
  --ion-backdrop-color: var(--ion-color-Dark-Cerulean);
}

.modal-payment-property-owner-menu-desembolso{
    --border-radius: 16px 16px 0px 0px;
    --ion-backdrop-opacity: 1;
}

.modal-create-profile-user-item{
    --width:1076px;
    --border-radius: 8px;
    --border: 2px solid var(--ion-color-tertiary);
    --background: var(--ion-color-primary-two);

    /* Shadow */
    --box-shadow: 0px 2px 10px 0px rgba(143, 162, 160, 0.40);
    --height: auto;
}

.modal-profile-plan-services{
    --border-radius: 8px;
    --background: var(--ion-color-primary-two);
    --height: auto;
}

.ql-container {
  font-size: 16px !important;
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}

.modal-default-radius {
  --border-radius: 1rem;
  padding: 5rem 1rem;
  margin: auto;
}

// _scrollbar.scss
@mixin custom-scrollbar {
    // WebKit Browsers (Chrome, Safari)
    &::-webkit-scrollbar {
      width: 12px; // ancho del scrollbar
      height: 12px; // altura del scrollbar en caso de overflow-x
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1; // color de fondo del track
    }
  
    &::-webkit-scrollbar-thumb {
      background: #888; // color del thumb
      border-radius: 6px; // radio de borde del thumb
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #555; // color del thumb al pasar el mouse
    }
  
    // Firefox
    scrollbar-width: thin; // tamaño del scrollbar
    scrollbar-color: #888 #f1f1f1; // color del thumb y del track
  }
  
  // Edge and IE (Applying custom styles for Edge and IE might be limited as they don't support full customization like WebKit and Firefox)
  @mixin edge-ie-scrollbar {
    & {
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }
  
  .text-noti p {
    margin: 0;
  }